import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { Label } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { withRouter, Link } from "react-router-dom";

// users
import user1 from "../../../assets/images/users/avatar_blank.jpg";

//redux
import { useSelector } from "react-redux";

import {
  Modal
} from "reactstrap";
import { post } from "src/helpers/api_helper";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

const ProfileMenu = (props: any) => {
  const { success } = useSelector((state: any) => ({
    success: state.profile.success,
  }));

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false);

  const [username, setusername] = useState("Admin");

  const [modal_standard, setmodal_standard] = useState(false);

  const [isProgress, setIsProgress] = useState(0);

  const [success_msg, setsuccess_msg] = useState(false);

  const [success_message, setsuccess_message] = useState(false);

  const [data, setData] = useState({ success : null,  message : { password : null } }); 
 
  const handleValidSubmit = (values: any) => {
    setIsProgress(1);
    const sendPostRequest = async () => {
      try {

        const resp:any = await post(process.env.REACT_APP_API_URL + '/api/change_password', values);
      //  console.log(resp.message);
      setsuccess_message(resp.success);
       setData(resp);
         
        tog_standard();
        setsuccess_msg(true);
       // setData(resp)
        setIsProgress(0);

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendPostRequest();
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  useEffect(() => {
    const getAuthUser = localStorage.getItem("authUser");
    if (getAuthUser) {
      if (
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(getAuthUser);
        setusername(obj.name);
      }
    }
  }, [success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">          
          <DropdownItem tag="a" href="/#" onClick={(e) => { e.preventDefault(); tog_standard(); }}>
            <i className="bx bx-pencil font-size-16 align-middle me-1" />
            {props.t("Change Password")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      {success_msg ? (
        <SweetAlert
          success
          title="Password changed successfully"          
          onCancel={() => {
            setsuccess_msg(false);
          }}
          onConfirm={() => {
            setsuccess_msg(false);
          }}
          timeout={2000}
        />        
        
      ) : null}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        id="change_password"
      >
        <AvForm onValidSubmit={(e: any, v: any) => {
          handleValidSubmit(v);
        }}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Change Password
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <div className="mb-3">
              <Label>Password</Label>
              <AvField
                name="password"
                type="password"
                placeholder="Password"
                errorMessage="Enter password"
                validate={{ 
                  required: { value: true },
                  pattern: {value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage:"Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length"},
                  minLength: {value: 6,errorMessage:"Password must contain atleast 6 characters"}
                }}
              />
            </div>
            <div className="mb-3">
              <AvField
                name="password_confirmation"
                type="password"
                placeholder="Re-type Password"
                errorMessage="Enter Re-password"
                validate={{
                  required: { value: true },
                  match: { value: "password" },
                  pattern: {value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage:"Password must contain one uppercase letter, one lower case, one special character and one digit and minimum 6 characters in length"},
                  minLength: {value: 6,errorMessage:"Password must contain atleast 6 characters"}
                }}
              />
            </div>

          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard();
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>

            {isProgress === 1 ?
              (
                <button type="submit" className="btn btn-primary " disabled={true}>
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>Submiting
                </button>
              ) : (
                <button type="submit" className="btn btn-primary ">
                  Submit
                </button>
              )
            }
          </div>
        </AvForm>
      </Modal>

    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(ProfileMenu));
