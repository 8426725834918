import { Redirect } from "react-router-dom";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//GST Request
import GSTRequest from "../pages/GSTRequest"

//Coupon
import Coupon from "src/pages/Coupon";

//customers
import Customers from "src/pages/Customers";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";
import Verify from "src/pages/Authentication/Verify";
import Error404 from "src/pages/Utility/Error404";

import { createBrowserHistory } from "history";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const defaultHistory = createBrowserHistory();

/* const {
  REACT_APP_PAYROLL_HOST: payrollHost
} = process.env;


function Payroll({ history }: any) { 
  return <MicroFrontend history={history} host={payrollHost} name="Payroll" />;
} */

const userRoutes: Array<RouteProps> = [

  //User Profile
  { path: "/profile", component: userProfile },

  //gst-request
  { path: "/gst-request", component: GSTRequest },

  //Coupon
  { path: "/coupon", component: Coupon },
  
  //customers
  { path: "/customers", component: Customers },

  //Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-fontawesome", component: IconFontawesome },
 
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/gst-request" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login }, 
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/verify/:code", component: Verify },
  { path: "/pages-404", component: Error404 },  
];

export { userRoutes, authRoutes };
