import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Col, Row } from "reactstrap";
import NoDataIndication from "./NoDataIndication";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { getAccessToken, post } from "src/helpers/api_helper";
import moment from "moment";

interface ChangeProp {
  page: number;
  sizePerPage: number;
}

interface Props {
  link: string;
  defaultSorted: any;
  columns: any;
  refresh: any;
}

const Grid = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState("asc");
  const [data, setData] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const pageOptions: any = {
    sizePerPage: sizePerPage,
    totalSize: totalSize,
    custom: true,
  };

  var token = getAccessToken();

  const fetchCustomers = async (param: any) => {
    try {
      const response = await post(
        process.env.REACT_APP_API_URL + `/api/${props.link}`,
        {
          page: param.page,
          sizePerPage: param.sizePerPage,
          searchText: param.searchText,
          sortField: sortField,
          sortOrder: sortOrder,
        },
        {
          headers: { Authorization: token },
        }
      );

      if (response.success == true) {
        // Set the state values
        setTotalSize(response.count);
        setData(response.data);
        setCurrentPage(param.page);
      } else {
        // Handle the case where success is not true
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTableChange = (type: string, param: ChangeProp) => {
    setSizePerPage(param.sizePerPage);
    fetchCustomers(param);
  };

  useEffect(() => {
    fetchCustomers({
      page: currentPage,
      searchText: "",
      sizePerPage: sizePerPage,
      sortField: sortField,
      sortOrder: sortOrder,
    });
  }, [refresh, sizePerPage, sortField, sortOrder]);

  data.sort(
    (a: any, b: any) =>
      moment(a.serial_number).valueOf() - moment(b.serial_number).valueOf()
  );
  const { SearchBar } = Search;
  return (
    <React.Fragment>
      <PaginationProvider pagination={paginationFactory(pageOptions)}>
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            columns={props.columns}
            data={data}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                {/* <Row className="mb-2">
                  <Col md="4">
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row> */}
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        // responsive
                        remote
                        bordered={false}
                        striped={false}
                        defaultSorted={props.defaultSorted}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        onTableChange={handleTableChange}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        noDataIndication={() => <NoDataIndication />}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="inner-custom-pagination d-flex">
                    <div className="d-inline">
                      <SizePerPageDropdownStandalone {...paginationProps} />
                      <PaginationTotalStandalone {...paginationProps} />
                    </div>
                    <div className="text-md-right ms-auto">
                      <PaginationListStandalone {...paginationProps} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </React.Fragment>
  );
};

export default Grid;
