import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert, Container, Button } from "reactstrap";

// availity-reactstrap-validation
import { AvForm, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";

// action
// import { registerUser, apiError } from "../../store/actions";

//redux
// import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo_login.png";
import CarouselPageNew from "./CarouselPageNew";

import axios from 'axios';
import { authentication } from "src/helpers/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import CarouselPageVerify from "./CarouselPageVerify";
import RegisterWizard from "./RegisterWizard";


const Register = () => {
  // const dispatch = useDispatch();
  const [data, setData] = useState({ success: null, message: "", data: { exist: null } });
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [otp, setotp] = useState('');
  const [isVerification, SetIsVerification] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isProgress, setIsProgress] = useState(0);
  const [isSending, setIsSending] = useState(0);

  const appName = process.env.REACT_APP_NAME;

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.

      }
    }, authentication);
  }
  const resendOTP = () => {
    if (phoneNumber.length >= 12) {
      SetIsVerification(true);
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
        .then(confirmationResult => {
          window.confirmationResult = confirmationResult;
          setIsProgress(true);
          setErrorMessage('');
          setSuccessMessage('OPT sent your phone');
        }).catch((error) => {
          setSuccessMessage('');
          setErrorMessage('OTP sending failed, try again');
          SetIsVerification(false);
          console.log('opt sending..', error);
        });
    }
  }
  const requestOTP = async () => {
    if (isVerification) {
      verifyOTP();
    } else {
      if (phoneNumber.length >= 12) {
        const resp = await axios.get(process.env.REACT_APP_API_URL + '/api/check_mob_exists/' + phoneNumber);
        if (resp.data.data.exists) {
          setSuccessMessage('');
          setErrorMessage('Your mobile already registered with us, Please login to continue');
        } else {
          SetIsVerification(true);
          generateRecaptcha();
          let appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
            .then(confirmationResult => {
              window.confirmationResult = confirmationResult;
              setIsProgress(true);
              setErrorMessage('');
              setSuccessMessage('OPT sent your phone');
            }).catch((error) => {
              setSuccessMessage('');
              setErrorMessage('OTP sending failed, try again');
              SetIsVerification(false);
              console.log('opt sending..', error);
            });
        }
      }
    }
  }

  const verifyOTP = async () => {
    let confirmationResult = window.confirmationResult;
    confirmationResult.confirm(otp).then((result) => {
      //user signed successfully
      setErrorMessage('');
      setSuccessMessage('OPT verified successfully');
      setShowRegister(true);
    }).catch((error) => {
      setSuccessMessage('');
      setErrorMessage('OTP verification failed, try again');
      SetIsVerification(false);
      console.log('opt verifying..', error);
    });
  }

  // handleValidSubmit
  const handleValidSubmit = (values) => {
    setIsProgress(1);
    const sendPostRequest = async () => {
      try {

        const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/register', values);
        setData(resp.data);

        if (resp.data.success === false) {
          if (resp.data.data.exist === 2) {

          }
          setIsProgress(0);
        }

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendPostRequest();
    // dispatch(registerUser(values));
  };

  /*   useEffect(() => {
      dispatch(apiError(""));
    }, [dispatch]); */


  async function handleResend() {
    setIsSending(1);
    try {

      const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/resend_mail', { 'email': email });
      setData(resp.data);

      setIsSending(0);


    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register {appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          {showRegister ? (
            <Row className="g-0">
              <CarouselPageVerify />
              <RegisterWizard companyTelephone={phoneNumber} />
            </Row>
          ) :
            (
              <Row className="g-0">
                <CarouselPageNew />
                <Col lg={4} md={5} className="col-xxl-4">
                  <div className="auth-full-page-content d-flex p-sm-5 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4 mb-md-5 text-center">
                          <Link to="/" className="d-block auth-logo">

                            <img className="img-fluid" src={logo} alt="" />
                            {" "}
                          </Link>
                        </div>
                        {(data.success == true || data.data.exist === 2) ? (
                          <div className="auth-content my-auto">
                            <div className="text-center">
                              <div className="avatar-lg mx-auto">
                                <div className="avatar-title rounded-circle bg-light">
                                  <i className="bx bx-mail-send h2 mb-0 text-primary"></i>
                                </div>
                              </div>
                              <div className="p-2 mt-4">
                                <h4>Verify your email</h4>
                                {data.data.exist === 2 ? (
                                  <p dangerouslySetInnerHTML={{ __html: data.message }} ></p>
                                ) : (
                                  <p>
                                    We have sent you verification email to {" "}
                                    <span className="fw-bold">{email}</span>,
                                    Please check it
                                  </p>
                                )}


                                <div className="mt-4">
                                  <Link
                                    to="/"
                                    className="btn btn-primary w-10"
                                  >
                                    Back to Home
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 text-center">
                              <p className="text-muted mb-0">
                                Did't receive an email ?{" "}
                                <Link to="#" className="fw-semibold" onClick={handleResend}>
                                  {" "}
                                  Resend {isSending ? (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div>
                                  ) : null} {" "}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="auth-content my-auto">
                            <div className="text-center">
                              <h5 className="mb-0">Register Account</h5>
                              <p className="text-muted mt-2">
                                Get your {appName} account now.
                              </p>
                            </div>

                            <AvForm
                              className="needs-validation custom-form mt-4 pt-2"
                              onValidSubmit={(e, v) => {
                                requestOTP();
                              }}
                            >

                              {successMessage && (
                                <Alert color="success">
                                  {successMessage}
                                </Alert>
                              )}
                              {errorMessage && (
                                <Alert color="danger">
                                  {errorMessage}
                                </Alert>
                              )}

                              <div className="mb-3">
                                <AvField
                                  name="phoneNumber"
                                  label="Phone Number"
                                  type="text"
                                  value={phoneNumber}
                                  onChange={(e) => setPhoneNumber(e.target.value)}
                                  required
                                  placeholder="Phone Number"
                                />
                              </div>

                              {isVerification && (
                                <div className="mb-3">
                                  <div className="d-flex align-items-start">
                                    <div className="flex-grow-1">
                                      <label className="form-label">Enter Your OTP</label>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <div className="">
                                        <Link
                                          to="#"
                                          onClick={(e) => { resendOTP() }}
                                          className="text-muted"
                                        >
                                          Resend OTP?
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <AvField
                                    name="opt"
                                    // label="Enter Your OTP"
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setotp(e.target.value)}
                                    required
                                    placeholder="Enter Your OTP"
                                  />
                                </div>
                              )}


                              <div className="mb-3">

                                <button
                                  className="btn blk-btn w-100 waves-effect waves-light"
                                  type="submit"
                                >
                                  {isVerification ? 'Verify OTP' : 'Send OTP'}
                                </button>

                              </div>
                            </AvForm>
                            <div id="sign-in-button"></div>
                            <div className="mt-5 text-center">
                              <p className="text-muted mb-0">
                                Already have an account ?{" "}
                                <Link
                                  to="/login"
                                  className="fw-semibold"
                                >
                                  {" "}
                                  Login{" "}
                                </Link>{" "}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="mt-4 mt-md-5 text-center">
                          <p className="mb-0">
                            © {new Date().getFullYear()} {appName}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>

              </Row>
            )}


        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
