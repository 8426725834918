import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Button,
  Alert,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo_login.png";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { Razorpay } from "./Razorpay";
import { round } from "lodash";

type WizardState = {
  breadcrumbItems: Object;
  activeTabProgress: number;
  payment: number;
  designation: string;
  phone: string;
  password: string;
  cpassword: string;
  companyName: string;
  companyAaddress: string;
  companyAaddress2: string;
  companyTownOrCity: string;
  companyState: string;
  companyPincode: string;
  gstno: string;
  companyEmail: string;
  companyTelephone: string;
  code: string;
  amount: number;
  r_key: string;
  order_id: string;
  currency: string;
  merchant_order_id: string;
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
  is_success: number;
  is_progress: number;
  is_error: number;
  error: string;
  plans: Array<any>;
  plan_name: string;
  states: Array<any>;
  firstName: string;
  secondName: string;
  email: string;
};
/* interface MatchParams {
  code: string;
} */

interface RegProps extends RouteComponentProps {
  companyTelephone: string;
}


class RegisterWizard extends Component<RegProps, WizardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Wizard", link: "#" },
      ],
      activeTabProgress: 1,
      payment: 1,
      designation: "",
      phone: "",
      password: "",
      cpassword: "",
      companyName: "",
      companyAaddress: "",
      companyAaddress2: "",
      companyTownOrCity: "",
      companyState: "",
      companyPincode: "",
      gstno: "",
      companyEmail: "",
      companyTelephone: "",
      code: '',
      amount: 0,
      r_key: '',
      order_id: '',
      currency: '',
      merchant_order_id: '',
      razorpay_payment_id: '',
      razorpay_order_id: '',
      razorpay_signature: '',
      is_success: 0,
      is_progress: 0,
      is_error: 0,
      error: '',
      plans: [],
      plan_name: '',
      states: [],
      firstName: '',
      secondName: '',
      email: ''
    };
  }

  onChangehandler = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let data: any = {};
    data[name] = value;
    this.setState(data);
  }
  handleInvalidSubmit = (event: any, errors: Array<any>, values: any) => {

  }

  handleValidSubmit = async (event: any, values: any) => {

    const sendGetRequest = async () => {
      try {
        this.setState({
          is_progress: 1,
        });
        const resp = await axios.post(process.env.REACT_APP_API_URL + '/api/create_account_otp', this.state);
        if (resp.data.success) {          
          this.setState({
            is_success: 1,
          });
          localStorage.setItem("authUser", JSON.stringify(resp.data.data));
          this.props.history.push("/");
        } else {
          this.setState({
            is_success: 0,
            is_progress: 0,
            is_error: 1,
            error: resp.data.message
          });
        }

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();

  }

  componentDidMount() {

    const sendGetRequest = async () => {
      try {


        const resp = await axios.get(process.env.REACT_APP_API_URL + '/api/register_init');
        if (!resp.data.data.verified) {
          this.props.history.push("/pages-404");
        } else {
          this.setState({ plans: resp.data.data.plans, states: resp.data.data.states });
        }

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };

    sendGetRequest();

  }


  componentDidUpdate(prevProps: { companyTelephone: string }, prevState: WizardState) {
    if (this.props.companyTelephone !== this.state.companyTelephone) {
      this.setState({
        companyTelephone: this.props.companyTelephone
      })
    }
  }

  render() {
    const appName = process.env.REACT_APP_NAME;

    return (
      <React.Fragment>
        <Col lg={8} md={6} className="col-xxl-8 auth-full-page-content">
          <AvForm id="form1" onInvalidSubmit={this.handleInvalidSubmit} onValidSubmit={this.handleValidSubmit}>
            <div className="w-100">
              {this.state.is_progress ?
                (
                  <CardBody>
                    <div className="mb-4 mt-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        {/* <img className="img-fluid" src={logo} height="90" alt="" /> */}
                        <img src={logo} alt="" height="90" />
                        {" "}
                        {/* <span className="logo-txt">{appName}</span> */}
                      </Link>
                    </div>
                    <div className="text-center">
                      <h5 className="mb-0">Register Account</h5>
                      <p className="text-muted mt-2">
                        Get your {appName} account now.
                      </p>
                    </div>
                    {this.state.is_success === 0 ?
                      (
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              <div>
                                <h5>Account Creating</h5>
                                <p className="text-muted">
                                  Please wait, your invoice management system will be ready soon.<br />
                                  (It may take few seconds.)
                                </p>
                              </div>
                            </div>
                          </Col>
                        </div>
                      ) : (
                        <div className="row justify-content-center">
                          <Col lg="6">
                            <div className="text-center">
                              <div className="mb-4">
                                <i className="mdi mdi-check-circle-outline text-success display-4"></i>
                              </div>
                              <div>
                                <h5>Success</h5>
                                <p className="text-muted">
                                  Your account has been successfully created. Thank you for choosing {appName}.
                                </p>
                                <div className="mt-4">
                                  <Link
                                    to="/"
                                    className="btn btn-primary w-10"
                                  >
                                    Back to Home
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </div>
                      )
                    }
                  </CardBody>
                ) : (
                  <CardBody>

                    <div className="mb-4 mt-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img src={logo} alt="" height="90" />{" "}
                        {/* <span className="logo-txt">{appName}</span> */}
                      </Link>
                    </div>
                    <div className="text-center">
                      <h5 className="mb-0">Register Account</h5>
                      <p className="text-muted mt-2">
                        Get your {appName} account now.
                      </p>
                    </div>
                    {this.state.is_error === 1 ?
                      (
                        <Alert color="danger" className="mx-2">
                          <div 
                            dangerouslySetInnerHTML={{ __html: this.state.error }}
                          />                          
                        </Alert>
                      ) : null
                    }

                    <div id="progrss-wizard" className="twitter-bs-wizard px-5">

                      <div className="text-center mb-4">
                        <p className="card-title-desc">
                          Fill all information below
                        </p>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="progresspill-firstName-input">
                              First Name
                            </label>
                            <AvField
                              type="text"
                              name="firstName"
                              id="progresspill-firstName-input"
                              className="form-control"
                              onChange={this.onChangehandler}
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="progresspill-secondName-input">
                              Last Name
                            </label>
                            <AvField
                              type="text"
                              className="form-control"
                              name="secondName"
                              id="progresspill-secondName-input"
                              onChange={this.onChangehandler}
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </div> 
                      </div>
                      <div className="row">*/}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label
                              htmlFor="progresspill-company-name-input"
                              className="form-label"
                            >
                              Company Name
                            </label>
                            <AvField
                              type="text"
                              className="form-control"
                              id="progresspill-company-name-input"
                              name="companyName"
                              onChange={this.onChangehandler}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="progresspill-email-input">
                              Email
                            </label>
                            <AvField
                              type="email"
                              name="email"
                              id="progresspill-email-input"
                              className="form-control"
                              onChange={this.onChangehandler}
                              validate={{ required: { value: true } }}
                            />
                          </div>
                        </div>
                        {/* </div>
                      <div className="row"> */}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="progresspill-password-input">
                              Password
                            </label>
                            <AvField
                              type="password"
                              className="form-control"
                              id="progresspill-password-input"
                              name="password"
                              onChange={this.onChangehandler}
                              validate={{
                                required: { value: true },
                                pattern: { value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage: "Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length" },
                                minLength: { value: 6, errorMessage: "Password must contain atleast 6 characters" }
                              }}
                            />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="progresspill-cpassword-input">
                              Confirm Password
                            </label>
                            <AvField
                              type="password"
                              className="form-control"
                              id="progresspill-cpassword-input"
                              name="cpassword"
                              validate={{
                                required: { value: true },
                                match: { value: "password" },
                                pattern: { value: '/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/', errorMessage: "Password must contain one uppercase letter, one lower case, one special character,one digit and minimum 6 characters in length" },
                                minLength: { value: 6, errorMessage: "Password must contain atleast 6 characters" }
                              }}
                              onChange={this.onChangehandler}
                            />
                          </div>
                        </div> */}
                      </div>
                      {/* <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label htmlFor="progresspill-company-companyState-input">
                              State
                            </label>
                            <AvField type="select" name="companyState" validate={{ required: { value: true } }} onChange={this.onChangehandler}>
                              <option key={-1} value={""}>--Select--</option>
                              {this.state.states.map((state) => (
                                <option value={state.id} key={state.id}>{state.state}</option>
                              ))}
                            </AvField>
                          </div>
                        </div>
                      </div> */}
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className="next"
                        >
                          <Button type="submit" color="primary">Submit</Button>
                        </li>
                      </ul>
                    </div>
                  </CardBody>)
              }

            </div>
            <div className="mt-4 mt-md-5 text-center position-relative Register-footer" >
              <p className="mb-0">
                © {new Date().getFullYear()} {appName}
              </p>
            </div>
          </AvForm>

        </Col>
      </React.Fragment>
    );
  }
}

export default withRouter(RegisterWizard);
