import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React, { useState } from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo_login.png";

//Import config
import config from "../../config";
// import CarouselPage from "./CarouselPage";
import CarouselPageNew from "./CarouselPageNew";
import LoginOtp from "./LoginOtp";
import LoginEmail from "./LoginEmail";

interface LoginProps {
  history: object;
}

const Login = ({ history }: LoginProps) => {  
  const [type,setType] = useState(1); 
  const appName = process.env.REACT_APP_NAME;

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | {appName}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPageNew />
            <Col lg={4} md={5} className="col-xxl-4">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/" className="d-block auth-logo">
                        <img className="img-fluid" src={logo} alt="" />
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        {/* <h5 className="mb-0">Welcome Back !</h5> */}
                        <p className="text-muted mt-2">
                          Sign in to continue to {appName}.
                        </p>
                      </div> 
                         <LoginEmail /> 
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} {appName}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default withRouter(Login);
